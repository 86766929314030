export default function useEmployeeList() {
  const blankData = {
    name: '',
    calendarColor: '',
  }

  return {
    blankData,
  }
}
