<template>
  <div class="p-2">
    <validation-provider
      #default="validationContext"
      name="Name"
      rules="required"
    >
      <b-form-group
        label="Name"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="data.name"
          autofocus
          :state="getValidationState(validationContext)"
          trim
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      name="Calendar color"
      rules="required"
    >
      <b-form-group
        label="Calendar color"
        label-for="calendar-color"
      >
        <b-form-input
          id="calendar-color"
          v-model="data.calendarColor"
          autofocus
          :state="getValidationState(validationContext)"
          trim
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      name="Phone number"
    >
      <b-form-group
        label="Phone number"
        label-for="phone-number"
      >
        <b-form-input
          id="phone-number"
          v-model="data.phoneNumber"
          autofocus
          :state="getValidationState(validationContext)"
          trim
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import useEmployeeList from './useEmployeeList'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, ctx) {
    const {
      blankData,
    } = useEmployeeList()

    ctx.emit('start-data', blankData)

    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
